<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="用户id，外键{zb_user.id}" prop="userId">
                <a-input v-model="queryParam.userId" placeholder="请输入用户id，外键{zb_user.id}" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="标签(如：公司 ,家,学校 等等)" prop="addressLabel">
                  <a-input v-model="queryParam.addressLabel" placeholder="请输入标签(如：公司 ,家,学校 等等)"
                           allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="收货人姓名" prop="reciverName">
                  <a-input v-model="queryParam.reciverName" placeholder="请输入收货人姓名" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="收货人电话" prop="reciverTelephone">
                  <a-input v-model="queryParam.reciverTelephone" placeholder="请输入收货人电话" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否默认" prop="isDefault">
                  <a-input v-model="queryParam.isDefault" placeholder="请输入是否默认" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="经度" prop="lng">
                  <a-input v-model="queryParam.lng" placeholder="请输入经度" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="纬度" prop="lat">
                  <a-input v-model="queryParam.lat" placeholder="请输入纬度" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="省市区地区信息id(省份)外键{extra_area.area_id}" prop="areapId">
                  <a-input v-model="queryParam.areapId"
                           placeholder="请输入省市区地区信息id(省份)外键{extra_area.area_id}" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="省份" prop="areap">
                  <a-input v-model="queryParam.areap" placeholder="请输入省份" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="省市区地区信息id(城市)外键{extra_area.area_id}" prop="areacId">
                  <a-input v-model="queryParam.areacId"
                           placeholder="请输入省市区地区信息id(城市)外键{extra_area.area_id}" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="城市" prop="areac">
                  <a-input v-model="queryParam.areac" placeholder="请输入城市" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="省市区地区信息id(地区)外键{extra_area.area_id}" prop="areaxId">
                  <a-input v-model="queryParam.areaxId"
                           placeholder="请输入省市区地区信息id(地区)外键{extra_area.area_id}" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="县" prop="areax">
                  <a-input v-model="queryParam.areax" placeholder="请输入县" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="详细地址" prop="detailedAddress">
                  <a-input v-model="queryParam.detailedAddress" placeholder="请输入详细地址" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['user:address:add']">
          <a-icon type="plus"/>
          新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)"
                  v-hasPermi="['user:address:edit']">
          <a-icon type="edit"/>
          修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:address:remove']">
          <a-icon type="delete"/>
          删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['user:address:export']">
          <a-icon type="download"/>
          导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['user:address:edit']"/>
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['user:address:edit']">
            <a-icon type="edit"/>修改
          </a>
          <a-divider type="vertical" v-hasPermi="['user:address:remove']"/>
          <a @click="handleDelete(record)" v-hasPermi="['user:address:remove']">
            <a-icon type="delete"/>删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageAddress, listAddress, delAddress} from '@/api/user/address'
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'

export default {
  name: 'Address',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        sex: null,
        addressLabel: null,
        reciverName: null,
        reciverTelephone: null,
        isDefault: null,
        lng: null,
        lat: null,
        areapId: null,
        areap: null,
        areacId: null,
        areac: null,
        areaxId: null,
        areax: null,
        detailedAddress: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '主键ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户id，外键{zb_user.id}',
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '性别,0-保密 1-男 2-女',
          dataIndex: 'sex',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '标签(如：公司 ,家,学校 等等)',
          dataIndex: 'addressLabel',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '收货人姓名',
          dataIndex: 'reciverName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '收货人电话',
          dataIndex: 'reciverTelephone',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否默认',
          dataIndex: 'isDefault',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '经度',
          dataIndex: 'lng',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '纬度',
          dataIndex: 'lat',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '省市区地区信息id(省份)外键{extra_area.area_id}',
          dataIndex: 'areapId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '省份',
          dataIndex: 'areap',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '省市区地区信息id(城市)外键{extra_area.area_id}',
          dataIndex: 'areacId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '城市',
          dataIndex: 'areac',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '省市区地区信息id(地区)外键{extra_area.area_id}',
          dataIndex: 'areaxId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '县',
          dataIndex: 'areax',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '详细地址',
          dataIndex: 'detailedAddress',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: {customRender: 'createTime'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询地址列表 */
    getList() {
      this.loading = true
      pageAddress(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        userId: undefined,
        sex: undefined,
        addressLabel: undefined,
        reciverName: undefined,
        reciverTelephone: undefined,
        isDefault: undefined,
        lng: undefined,
        lat: undefined,
        areapId: undefined,
        areap: undefined,
        areacId: undefined,
        areac: undefined,
        areaxId: undefined,
        areax: undefined,
        detailedAddress: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map(id => data.push({"id": id}))
          return delAddress(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('user/user-address/export', {
            ...that.queryParam
          }, `地址_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
